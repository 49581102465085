body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 60vh;
    font-size: calc(8px + 2vmin);
    background-color: #F4F7F6;
}

.profile {
    margin: 8rem 0 2rem 0;
    width:300px;
    height:300px;
    object-fit:cover;
    border-radius: 50%;
}

.about-text {
    color: black;
}

@media (max-width: 375px) {
    .profile {
        margin-top: 4rem;
    }

    .about {
        font-size: calc(6px + 2vmin);
    }
}
.contact a {
    outline: 0;
}

.contact {
    background-color: #F4F7F6;
    height: 20vh;
}

#instagram {
    height: 50px;
    width: 86px;
}

#instagram:hover {
    background-image: url(/static/media/icons-instagram-filled.134424de.svg) 50px 86px;
}

#linkedin {
    height: 50px;
    width: 86px;
}

#linkedin:hover {
    background-image: url(/static/media/icons-linkedin-filled.8813bdf3.svg) 50px 86px;
}

html {
    font-size: 100%;
}

hr {
    margin: 40px 0px;
    border-color: white;
    opacity: 0.2;
}

.exp-container {
    padding: 40px 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    background-color: #282c34;
}

h2 {
    font-size: 54px;
    text-align: center;
}

h2, h3 {
    font-weight: 300;
    color: #FFFFFF;
}

p, h4 {
    color: #FFFFFF;
}

p.lead {
    text-align: center;
    color: #FFFFFF;
    font-size: 21px;
}

.lead {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.para {
    display: block;
    text-align: left;
}

@media (max-width: 767px) {
    .para {
        display: block;
        text-align: center;
    }

    .hidden-phone {
        display: none;
    }
}

h2 {
    text-align: center;
}

.logo {
    line-height: 60px;
    position: fixed;
    float: left;
    padding: 15px 46px 25px 46px;
    text-decoration: none;
    outline: none;
}

.logo a:active, a:focus {
    outline: none;
}

.logo a h1 {
    text-decoration: none;
    letter-spacing: 2px;
    color: #fff;
    font-weight: bold;
    outline: none;
}

.logo a h1:hover {
    display:inline-block;
    text-decoration: none;
    color: black;
    outline: none;
}

.nav-bar { /*navbar styling*/
    z-index: 101;
    position: fixed;
    width: 100%;
    line-height: 60px;
}

.nav-bar ul { /*navbar styling*/
    line-height: 60px;
    list-style: none;
    background: rgba(0, 0, 0, 0);
    padding: 0;
    float: right;
    margin: 0;
    padding-right: 40px;
    transition: 1s;
}

.scroll { /*navbar styling*/
    background: #829191;
}

.nav-bar ul li { /*navbar styling*/
    display: inline-block;
    padding: 16px 40px;
}

.nav-bar ul li a { /*navbar styling*/
    text-decoration: none;
    color: #fff;
    font-size: 16px;
}

.nav-bar ul li a:hover { /*navbar styling*/
    color: black;
}

@media (max-width: 972px) {
    .ul-items {
        display: none;
    }
    
    .logo {
        width: 100%;
        background-color: #829191;
        padding-bottom: 10px;
    }

    .logo a h1:hover {
        display: block;
    }

    .nav-bar .scroll{ /*navbar styling*/
        background-color: #829191;
    }

    .logo h1 {
        font-size: 95%;
    }
}
button {
  cursor: pointer;
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}

.wrapper {
  position: absolute;
  display: flexbox;
  justify-content: center;
  align-items: center;
  left: 45%;
  top: 10%;
  z-index: 100;
}

.welcome p {
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 1452px) {
  .wrapper {
    left: 42%;
    top: 10%;
  }
}

@media (max-width: 972px) {
  .wrapper {
    left: 38%;
    top: 6%;
  }
}

@media (max-width: 764px) {
  .wrapper {
    left: 34%;
    top: 9%;
  }
}

@media (max-width: 414px) {
  .wrapper {
    left: 35%;
    top: 9%;
  }
}

@media (max-width: 376px) {
  .wrapper {
    left: 33%;
    top: 7%;
  }
}

@media (max-width: 360px) {
  .wrapper {
    left: 33%;
    top: 9%;
  }
}

.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: #282c34;
}

.particles-canvas[style] {
  position: relative !important;

  width: 100vw !important;
  height: 100vh !important;
  pointer-events: initial  !important;
}

