h2 {
    text-align: center;
}

.logo {
    line-height: 60px;
    position: fixed;
    float: left;
    padding: 15px 46px 25px 46px;
    text-decoration: none;
    outline: none;
}

.logo a:active, a:focus {
    outline: none;
}

.logo a h1 {
    text-decoration: none;
    letter-spacing: 2px;
    color: #fff;
    font-weight: bold;
    outline: none;
}

.logo a h1:hover {
    display:inline-block;
    text-decoration: none;
    color: black;
    outline: none;
}

.nav-bar { /*navbar styling*/
    z-index: 101;
    position: fixed;
    width: 100%;
    line-height: 60px;
}

.nav-bar ul { /*navbar styling*/
    line-height: 60px;
    list-style: none;
    background: rgba(0, 0, 0, 0);
    padding: 0;
    float: right;
    margin: 0;
    padding-right: 40px;
    transition: 1s;
}

.scroll { /*navbar styling*/
    background: #829191;
}

.nav-bar ul li { /*navbar styling*/
    display: inline-block;
    padding: 16px 40px;
}

.nav-bar ul li a { /*navbar styling*/
    text-decoration: none;
    color: #fff;
    font-size: 16px;
}

.nav-bar ul li a:hover { /*navbar styling*/
    color: black;
}

@media (max-width: 972px) {
    .ul-items {
        display: none;
    }
    
    .logo {
        width: 100%;
        background-color: #829191;
        padding-bottom: 10px;
    }

    .logo a h1:hover {
        display: block;
    }

    .nav-bar .scroll{ /*navbar styling*/
        background-color: #829191;
    }

    .logo h1 {
        font-size: 95%;
    }
}