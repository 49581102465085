.contact a {
    outline: 0;
}

.contact {
    background-color: #F4F7F6;
    height: 20vh;
}

#instagram {
    height: 50px;
    width: 86px;
}

#instagram:hover {
    background-image: url('../../assets/icons-instagram-filled.svg') 50px 86px;
}

#linkedin {
    height: 50px;
    width: 86px;
}

#linkedin:hover {
    background-image: url('../../assets/icons-linkedin-filled.svg') 50px 86px;
}
