.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 60vh;
    font-size: calc(8px + 2vmin);
    background-color: #F4F7F6;
}

.profile {
    margin: 8rem 0 2rem 0;
    width:300px;
    height:300px;
    object-fit:cover;
    border-radius: 50%;
}

.about-text {
    color: black;
}

@media (max-width: 375px) {
    .profile {
        margin-top: 4rem;
    }

    .about {
        font-size: calc(6px + 2vmin);
    }
}