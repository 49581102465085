.App {
  text-align: center;
  position: relative;
  overflow: hidden;
  background-color: #282c34;
}

.particles-canvas[style] {
  position: relative !important;

  width: 100vw !important;
  height: 100vh !important;
  pointer-events: initial  !important;
}
