button {
  cursor: pointer;
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	outline: inherit;
}

.wrapper {
  position: absolute;
  display: flexbox;
  justify-content: center;
  align-items: center;
  left: 45%;
  top: 10%;
  z-index: 100;
}

.welcome p {
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1452px) {
  .wrapper {
    left: 42%;
    top: 10%;
  }
}

@media (max-width: 972px) {
  .wrapper {
    left: 38%;
    top: 6%;
  }
}

@media (max-width: 764px) {
  .wrapper {
    left: 34%;
    top: 9%;
  }
}

@media (max-width: 414px) {
  .wrapper {
    left: 35%;
    top: 9%;
  }
}

@media (max-width: 376px) {
  .wrapper {
    left: 33%;
    top: 7%;
  }
}

@media (max-width: 360px) {
  .wrapper {
    left: 33%;
    top: 9%;
  }
}
