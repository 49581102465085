html {
    font-size: 100%;
}

hr {
    margin: 40px 0px;
    border-color: white;
    opacity: 0.2;
}

.exp-container {
    padding: 40px 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 60vh;
    background-color: #282c34;
}

h2 {
    font-size: 54px;
    text-align: center;
}

h2, h3 {
    font-weight: 300;
    color: #FFFFFF;
}

p, h4 {
    color: #FFFFFF;
}

p.lead {
    text-align: center;
    color: #FFFFFF;
    font-size: 21px;
}

.lead {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.para {
    display: block;
    text-align: left;
}

@media (max-width: 767px) {
    .para {
        display: block;
        text-align: center;
    }

    .hidden-phone {
        display: none;
    }
}
